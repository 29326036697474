@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

.schedule-h .card-head {
  margin-bottom: 30px;
  padding: 20px 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background-color: #26a65b;
  color: #fff; }
  .schedule-h .card-head .card-title {
    width: 100%;
    text-align: center; }

.schedule-h .schedule-card-h {
  width: 100%;
  margin: 0px 0px;
  background-color: #fff;
  overflow-x: scroll; }
  @media only screen and (max-width: 768px) {
    .schedule-h .schedule-card-h {
      display: flex; } }
  .schedule-h .schedule-card-h .card-inner {
    padding: 0 5px 0px;
    max-width: 20em; }
  .schedule-h .schedule-card-h .schedule-list-item {
    margin-bottom: 12px;
    padding: 5px 20px;
    border-left: 5px solid #173bb7;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    min-height: 5rem; }
    @media only screen and (max-width: 768px) {
      .schedule-h .schedule-card-h .schedule-list-item {
        width: 15rem; } }
    .schedule-h .schedule-card-h .schedule-list-item h3,
    .schedule-h .schedule-card-h .schedule-list-item h4 {
      margin-top: 5px;
      margin-bottom: 5px; }
    .schedule-h .schedule-card-h .schedule-list-item .item-content {
      min-width: 52%; }
    .schedule-h .schedule-card-h .schedule-list-item .item-label {
      position: relative;
      margin-top: 5px;
      text-transform: uppercase;
      font-size: 12px;
      color: #173bb7;
      min-width: 5%; }
      .schedule-h .schedule-card-h .schedule-list-item .item-label:before {
        position: absolute;
        left: -15px;
        top: 1.5px;
        width: 11px;
        height: 11px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #173bb7;
        content: ""; }
    .schedule-h .schedule-card-h .schedule-list-item.dj {
      border-left: 5px solid #26a65b; }
      .schedule-h .schedule-card-h .schedule-list-item.dj .item-label {
        color: #26a65b; }
        .schedule-h .schedule-card-h .schedule-list-item.dj .item-label:before {
          background-color: #26a65b; }
    .schedule-h .schedule-card-h .schedule-list-item.walkthrough {
      border-left: 5px solid #f4d03f; }
      .schedule-h .schedule-card-h .schedule-list-item.walkthrough .item-label {
        color: #f4d03f; }
        .schedule-h .schedule-card-h .schedule-list-item.walkthrough .item-label:before {
          background-color: #f4d03f; }
    .schedule-h .schedule-card-h .schedule-list-item.keynote {
      border-left: 5px solid #f47983; }
      .schedule-h .schedule-card-h .schedule-list-item.keynote .item-label {
        color: #f47983; }
        .schedule-h .schedule-card-h .schedule-list-item.keynote .item-label:before {
          background-color: #f47983; }
    .schedule-h .schedule-card-h .schedule-list-item:after {
      display: table;
      clear: both;
      content: ""; }
