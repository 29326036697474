#footer {
  color: #fff; }
  #footer h1, #footer h2, #footer h3, #footer h4, #footer h5, #footer h6 {
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem; }
  #footer p {
    color: #fff; }
  #footer .icons {
    background: rgba(128, 128, 128, 0.05);
    border-radius: 4em;
    padding: .35em .75em;
    font-size: 1.25em;
    cursor: default;
    display: inline-block;
    text-decoration: none; }
    #footer .icons li {
      display: inline-block; }
      #footer .icons li a {
        background: 0 0;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        text-align: center;
        border-radius: 100%;
        border: 0;
        color: inherit; }
        #footer .icons li a:hover {
          color: #00c800; }
    #footer .icons .label {
      display: none; }
  #footer .contact {
    text-align: center; }
    #footer .contact p {
      text-align: center;
      margin: 0 0 3em 0; }
  #footer .copyright {
    text-align: center;
    color: rgba(128, 128, 128, 0.75);
    font-size: 0.8em;
    cursor: default; }
    #footer .copyright a {
      color: rgba(128, 128, 128, 0.75); }
      #footer .copyright a:hover {
        color: rgba(212, 212, 212, 0.85); }
