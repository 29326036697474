@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

.checkbox-box {
  display: inline-flex; }

.checkbox-content {
  position: absolute;
  top: 16px;
  left: 40%;
  z-index: 99; }
  @media only screen and (max-width: 1350px) {
    .checkbox-content {
      left: 30%; } }
  @media only screen and (max-width: 1150px) {
    .checkbox-content {
      left: 20%; } }
  @media only screen and (max-width: 1023px) {
    .checkbox-content {
      left: 40%; } }
  .checkbox-content i {
    color: #4caf50;
    margin-top: 9px;
    vertical-align: middle;
    margin-right: 4px;
    margin-left: 4px; }

.checkbox-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; }
  .checkbox-switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .checkbox-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #272b32;
    -webkit-transition: .4s;
    transition: .4s; }
    .checkbox-switch .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: #fff;
      -webkit-transition: .4s;
      transition: .4s; }
    .checkbox-switch .slider.round {
      border-radius: 34px; }
    .checkbox-switch .slider.round:before {
      border-radius: 50%; }
  .checkbox-switch input:checked + .slider {
    background-color: #4caf50; }
  .checkbox-switch input:focus + .slider {
    box-shadow: 0 0 1px #4caf50; }
  .checkbox-switch input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); }

.past-time {
  opacity: 0.8;
  background-color: #c4c4c4 !important; }
