@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

.carousel {
  position: relative;
  overflow: hidden;
  padding: 2em 0em;
  margin-bottom: 0;
  background: #f0f0f0; }
  .carousel .backward, .carousel .forward {
    position: absolute;
    top: 50%;
    width: 6em;
    height: 12em;
    margin-top: -6em;
    cursor: pointer; }
  .carousel .backward:before, .carousel .forward:before {
    content: '';
    display: block;
    width: 6em;
    height: 6em;
    border-radius: 100%;
    top: 50%;
    background-color: rgba(72, 57, 73, 0.5);
    position: absolute;
    margin-top: -3em;
    -moz-transition: background-color .35s ease-in-out;
    -webkit-transition: background-color .35s ease-in-out;
    -o-transition: background-color .35s ease-in-out;
    -ms-transition: background-color .35s ease-in-out;
    transition: background-color .35s ease-in-out;
    -webkit-backface-visibility: hidden; }
  .carousel .backward:after, .carousel .forward:after {
    content: '';
    width: 3em;
    height: 3em;
    position: absolute;
    top: 50%;
    margin: -1.5em 0 0;
    background: url(../css/carousel_images/arrow.svg) center center no-repeat; }
  .carousel .backward:hover:before {
    background-color: rgba(0, 200, 0, 0.75); }
  .carousel .forward {
    display: block;
    right: 0; }
    .carousel .forward:hover:before {
      background-color: rgba(0, 200, 0, 0.75); }
    .carousel .forward:before {
      right: -3em; }
    .carousel .forward:after {
      right: -0.25em; }
  .carousel .backward {
    display: block; }
    .carousel .backward:before {
      left: -3em; }
    .carousel .backward:after {
      left: -.25em;
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      transform: scaleX(-1); }
  .carousel .reel {
    overflow: visible;
    white-space: nowrap;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding: 0 2em; }
  .carousel article {
    display: inline-block;
    width: 18em;
    background: #fff;
    text-align: center;
    padding: 0 1em 3em;
    margin: 0 2em 0 0;
    white-space: normal;
    opacity: 1;
    -moz-transition: opacity .75s ease-in-out;
    -webkit-transition: opacity .75s ease-in-out;
    -ms-transition: opacity .75s ease-in-out;
    transition: opacity 0.75s ease-in-out; }
    .carousel article.loading {
      opacity: 0; }
    .carousel article .image {
      position: relative;
      left: -1em;
      top: 0;
      width: auto;
      margin-right: -2em;
      margin-bottom: 3em; }
    .carousel article p {
      text-align: center; }
  @media only screen and (max-width: 768px) {
    .carousel .reel {
      overflow-x: scroll !important;
      overflow-y: hidden !important; }
    .carousel .forward, .carousel .backward {
      display: none !important; } }
