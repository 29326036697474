@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

.cookie-container {
  background: #333136;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 9999999;
  box-shadow: 0 -0.25rem 0.25rem 0 rgba(0, 0, 0, 0.4); }
  .cookie-container p {
    color: #fff;
    font-size: .85rem;
    line-height: 1.25rem;
    display: inline-block;
    max-width: 45rem;
    margin: 0 1rem; }
  .cookie-container button {
    background: #0c0;
    color: #fff;
    font-family: inherit;
    font-size: inherit;
    border: none;
    border-radius: .125rem;
    padding: .5rem 0;
    margin: 0;
    width: 100%;
    cursor: pointer; }
    .cookie-container button:hover {
      background: #00aa00; }
  .cookie-container #decline {
    background-color: #c80000; }
    .cookie-container #decline:hover {
      background-color: #a00000; }
  .cookie-container #noCookieBar {
    background-color: #ffb900; }
    .cookie-container #noCookieBar:hover {
      background-color: #e6a800; }

@media only screen and (max-width: 768px) {
  .cookie-container {
    flex: 1;
    flex-direction: column;
    align-self: center;
    max-height: 60%;
    width: 100vw;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.4); }
    .cookie-container p {
      max-width: 100%;
      padding: 1rem 0 0;
      margin: 0; }
    .cookie-container button {
      padding: 0.5rem 0;
      margin: 0;
      width: 100%;
      font-size: 0.7rem; } }

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }
