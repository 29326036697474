@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

#navbar {
  left: 0;
  top: 0;
  width: 100vw;
  cursor: default;
  background-color: #333136;
  border-bottom: 2px lime solid;
  z-index: 9999999;
  height: 4rem; }
  #navbar > a:before {
    display: inline-block;
    background: #878787;
    color: #e4e4e4;
    width: 1.65em;
    height: 1.65em;
    border-radius: 1.65em;
    line-height: 1.65em;
    text-align: center;
    box-shadow: 0.125em 0.175em 0 0 rgba(0, 0, 0, 0.125);
    margin-right: 0.75em; }
  #navbar > a:focus {
    color: #fff;
    text-shadow: 1px 1px 1px #333136; }
  #navbar > a:active {
    color: #fff;
    text-shadow: 1px 1px 1px #333136; }
  #navbar > a > span {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
    font-weight: 800;
    font-size: 1em;
    font-family: "Roboto","Source Sans Pro",sans-serif;
    line-height: 4rem;
    letter-spacing: 0.075em;
    padding: 0em 1.2em 0em 1.2em;
    outline: 0; }
  #navbar > a > p {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
    font-weight: 800;
    font-size: 1em;
    font-family: "Roboto","Source Sans Pro",sans-serif;
    line-height: 4rem;
    letter-spacing: 0.075em;
    padding: 0em 1.2em 0em 1.2em;
    outline: 0; }
  #navbar > a:focus {
    -moz-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;
    transition: all .4s ease-in;
    background-color: lime; }
    #navbar > a:focus > a:before {
      background: #ED786A;
      color: #fff; }
  #navbar > a:hover {
    -moz-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;
    transition: all .4s ease-in;
    background-color: lime; }
    #navbar > a:hover > a {
      color: #fff;
      text-shadow: 1px 1px 1px #333136; }
      #navbar > a:hover > a:before {
        background: #ED786A;
        color: #fff; }
  #navbar > a > ul {
    display: none; }

.navbar-brand {
  border-bottom: 2px solid lime; }
  .navbar-brand .navbar-item {
    padding: 0 0 0 .5rem; }
    .navbar-brand .navbar-item:hover {
      color: #00c800 !important; }
  .navbar-brand:hover {
    background-color: #323232;
    color: lime;
    border-bottom: 2px solid #00c800; }

.navbar-item {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: .9rem;
  font-family: "Roboto","Source Sans Pro",sans-serif;
  line-height: 4rem;
  letter-spacing: 0.075em;
  padding: 0em 1em;
  outline: 0; }
  .navbar-item .navbar-link {
    color: inherit !important;
    height: 4rem;
    line-height: 4rem;
    padding: 0.5rem 0; }
  .navbar-item:hover {
    color: #2a2a2a !important;
    background-color: #0f0; }
  .navbar-item:focus {
    background: #c4c4c4 !important;
    color: #1a1a1a !important;
    border-left: solid #080 !important; }
  .navbar-item img {
    max-height: 2.5rem !important;
    padding: 0 0.5rem 0 0; }

.navbar-item.has-dropdown {
  padding: 0 1em !important; }

.navbar-dropdown {
  background-color: #f0f0f0;
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
  border-top: 2px solid #0f0;
  box-shadow: 0 5px 2px rgba(10, 10, 10, 0.1);
  display: none;
  font-size: 0.875rem;
  left: -10%;
  min-width: 100%;
  position: absolute;
  top: 100%;
  z-index: 20;
  padding: 0; }
  .navbar-dropdown .navbar-item {
    color: #2a2a2a;
    font-size: 0.8rem;
    border-left: solid 4px #f0f0f0; }
    .navbar-dropdown .navbar-item:hover {
      background-color: #e0e0e0 !important;
      color: #2a2a2a !important;
      border-left: solid 4px #00ff00 !important; }

.dropotron {
  position: relative;
  background-color: #333136;
  border-radius: 3px;
  border: 2px #aaa solid;
  padding: 1.5rem;
  line-height: 2.5em;
  min-width: 10rem;
  margin-left: -0.1px; }
  .dropotron a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.8em;
    letter-spacing: 0.075em;
    outline: 0; }
  .dropotron span {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.8em;
    letter-spacing: 0.075em;
    outline: 0; }
  .dropotron li:hover > a {
    color: #00FF00; }
  .dropotron li:hover > span {
    color: #00FF00; }

.dropotron.level-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
  border-top: 2px lime; }

.dropotron.level-1 {
  background: #191e22;
  box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 0px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5), 0px 1px 28px -2px rgba(0, 0, 0, 0.5); }

#navPanel {
  display: none; }

#titleBar {
  display: none; }

@media only screen and (max-width: 768px) {
  .navbar-menu {
    max-width: 100%;
    overflow: scroll !important; }
  .navbar-item {
    color: #fafafa;
    line-height: 2rem; }
    .navbar-item:hover {
      background-color: #232323 !important;
      color: white !important; }
    .navbar-item .navbar-link {
      height: 3rem;
      line-height: 2rem; }
  .navbar-link:hover {
    background-color: #232323 !important;
    color: white !important; }
  .navbar-dropdown {
    background-color: #f0f0f0;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top: 2px solid #0f0;
    box-shadow: 0 5px 2px rgba(10, 10, 10, 0.1);
    display: unset !important;
    font-size: 0.875rem;
    left: -10%;
    position: unset !important;
    top: unset !important;
    min-width: 100%;
    z-index: 20;
    padding: 0; }
    .navbar-dropdown .navbar-item {
      color: #fafafa;
      font-size: 0.7rem;
      height: 2rem;
      line-height: 2rem;
      border-left: solid 4px #f0f0f0; }
      .navbar-dropdown .navbar-item:hover {
        background-color: #232323 !important;
        color: white !important;
        border-left: solid 4px #00ff00 !important; }
    .navbar-dropdown .navbar-link:hover {
      background-color: #232323 !important;
      color: white !important; }
  .dropotron {
    position: relative;
    background-color: #333136;
    border-radius: 3px;
    border: 2px #aaa solid;
    padding: 1.5rem;
    line-height: 2.5em;
    min-width: 10rem;
    margin-left: -0.1px; }
    .dropotron a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.8em;
      letter-spacing: 0.075em;
      outline: 0; }
    .dropotron span {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.8em;
      letter-spacing: 0.075em;
      outline: 0; }
    .dropotron li:hover > a {
      color: #00FF00; }
    .dropotron li:hover > span {
      color: #00FF00; }
  .dropotron.level-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
    border-top: 2px lime; }
  .dropotron.level-1 {
    background: #191e22;
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 0px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5), 0px 1px 28px -2px rgba(0, 0, 0, 0.5); } }
