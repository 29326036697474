@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

.dark {
  padding-top: 5rem; }

.is-full-height {
  min-height: calc(100vh - 4rem); }

.schedule-card-v {
  width: 100%;
  margin: 0px 0px;
  background-color: #fff; }
  .schedule-card-v .card-head {
    margin-bottom: 30px;
    padding: 30px 15px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    background-color: #080;
    color: #fff; }
  .schedule-card-v .card-inner {
    padding: 0 30px 30px; }
  .schedule-card-v .schedule-list-item {
    margin-bottom: 12px;
    padding: 5px 20px;
    border-left: 5px solid #173bb7;
    min-height: 5rem;
    background-color: #f4f4f4; }
    .schedule-card-v .schedule-list-item h3,
    .schedule-card-v .schedule-list-item h4 {
      margin-top: 5px;
      margin-bottom: 5px; }
    .schedule-card-v .schedule-list-item .item-content {
      min-width: 52%; }
    .schedule-card-v .schedule-list-item .item-label {
      position: relative;
      margin-top: 5px;
      text-transform: uppercase;
      font-size: 12px;
      color: #173bb7;
      min-width: 5%; }
      .schedule-card-v .schedule-list-item .item-label:before {
        position: absolute;
        left: -15px;
        top: 1.5px;
        width: 11px;
        height: 11px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #173bb7;
        content: ""; }
    .schedule-card-v .schedule-list-item.dj {
      border-left: 5px solid #0c0; }
      .schedule-card-v .schedule-list-item.dj .item-label {
        color: #0c0; }
        .schedule-card-v .schedule-list-item.dj .item-label:before {
          background-color: #0c0; }
    .schedule-card-v .schedule-list-item.walkthrough {
      border-left: 5px solid #ffb900; }
      .schedule-card-v .schedule-list-item.walkthrough .item-label {
        color: #ffb900; }
        .schedule-card-v .schedule-list-item.walkthrough .item-label:before {
          background-color: #ffb900; }
    .schedule-card-v .schedule-list-item.keynote {
      border-left: 5px solid #7851a9; }
      .schedule-card-v .schedule-list-item.keynote .item-label {
        color: #7851a9; }
        .schedule-card-v .schedule-list-item.keynote .item-label:before {
          background-color: #7851a9; }
    .schedule-card-v .schedule-list-item:after {
      display: table;
      clear: both;
      content: ""; }
