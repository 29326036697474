@import url(https://fonts.googleapis.com/css?family=VT323);
html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; }

.dark {
  background-color: #f0f0f0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #222;
  line-height: 1.25em; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
    text-decoration: none;
    border-bottom-color: transparent; }
  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h5 strong,
  h6 strong {
    font-weight: 600; }

h1 {
  font-size: 4em; }

h2 {
  font-size: 2.85em; }

h3 {
  font-size: 1.25em; }

h4 {
  font-size: 1em;
  margin: 0 0 .25em; }

.banner {
  padding: 4.5em 0;
  margin-bottom: 0;
  text-align: center; }

header p {
  font-size: 1.5em;
  font-weight: 400;
  margin: 1em 0 2em; }
  header p .small {
    font-size: 1.2em;
    margin: 0 0 2em; }

.is-full-width {
  width: 100%; }
