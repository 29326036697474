.tabrow {
  text-align: center;
  list-style: none;
  margin: 0px 0 0px;
  padding: 0;
  line-height: 35px;
  height: 4rem;
  overflow-y: hidden;
  overflow-x: scroll;
  font-size: 12px;
  position: relative;
  display: block;
  color: #555; }
  @media only screen and (max-width: 736px) {
    .tabrow {
      height: 2.4rem;
      display: flex; } }
  .tabrow a, .tabrow span {
    color: inherit;
    text-decoration: none;
    width: 100%;
    text-algin: center; }
  .tabrow li {
    background: #ecf0f1;
    background: -o-linear-gradient(top, #ecf0f1 50%, #f0f2f2 100%);
    background: -ms-linear-gradient(top, #ecf0f1 50%, #f0f2f2 100%);
    background: -moz-linear-gradient(top, #ecf0f1 50%, #f0f2f2 100%);
    background: -webkit-linear-gradient(top, #ecf0f1 50%, #f0f2f2 100%);
    background: linear-gradient(top, #ecf0f1 50%, #f0f2f2 100%);
    cursor: pointer;
    display: inline-flex;
    z-index: 0;
    padding: 0 30px;
    width: 10rem;
    min-width: 7rem;
    height: 2.4rem;
    line-height: 2.4rem;
    border-bottom: 2px solid #eaeaea;
    border-right: 1px solid #dfdfdf;
    transition: ease .2s; }
    .tabrow li.selected {
      background: #313131;
      color: white;
      z-index: 2;
      border-bottom: 4px solid #00c800; }
      .tabrow li.selected:before {
        box-shadow: 2px 0px 0 #FFF; }
      .tabrow li.selected:after {
        box-shadow: -2px 0px 0 #FFF; }
    .tabrow li:hover {
      border-bottom: 4px solid #00c800; }
