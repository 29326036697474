@import url("https://fonts.googleapis.com/css?family=Roboto");
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto");
  font-weight: normal;
  font-style: normal; }

#stream {
  padding-bottom: 0px; }

.stream {
  position: relative;
  margin: 4rem auto 0;
  width: 100%;
  height: calc(100vh - 7rem);
  overflow: hidden; }
  .stream:hover .info {
    opacity: 1 !important;
    bottom: 40px !important; }
    .stream:hover .info .small {
      padding-bottom: 0; }
  .stream:hover .vid {
    opacity: 0.9; }
  .stream:hover .menu-button {
    opacity: 0.4; }
  .stream nav {
    position: relative;
    z-index: 10; }
    .stream nav:hover .menu-button {
      opacity: 1 !important; }
    .stream nav .menu-active {
      opacity: 1 !important; }
      .stream nav .menu-active:before, .stream nav .menu-active:after {
        transition: all 0.3s; }
      .stream nav .menu-active:before {
        top: 2px !important;
        transform: rotate(45deg); }
      .stream nav .menu-active:after {
        top: 2px !important;
        transform: rotate(-45deg); }
    .stream nav .menu-button {
      position: absolute;
      z-index: 10;
      top: 4vh;
      right: 20px;
      width: 30px;
      height: 20px;
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.3s; }
      .stream nav .menu-button:before, .stream nav .menu-button:after {
        content: "";
        position: absolute;
        background-color: #ffffff;
        height: 4px;
        width: 30px;
        transition: all 0.3s; }
      .stream nav .menu-button:before {
        top: 0; }
      .stream nav .menu-button:after {
        top: 10px; }
    .stream nav .playlist-active {
      right: 0 !important; }
    .stream nav .playlist {
      position: absolute;
      z-index: 4;
      top: 0;
      right: -50%;
      width: 25%;
      height: calc(100vh - 8rem);
      padding: 0 0 0 0;
      box-sizing: border-box;
      transition: all 0.3s;
      background-color: rgba(33, 33, 33, 0.8); }
      .stream nav .playlist li {
        cursor: pointer;
        display: inline-block;
        font-size: 11px;
        padding: 10px 20px;
        border-bottom: 1px solid #323232;
        width: 100%;
        box-sizing: border-box; }
        .stream nav .playlist li:hover {
          background-color: rgba(182, 182, 182, 0.1); }
        .stream nav .playlist li:first-child {
          font-weight: 600;
          padding: 10px; }
          .stream nav .playlist li:first-child:hover {
            background-color: transparent !important; }
        .stream nav .playlist li span:before {
          content: "by";
          padding-right: 3px;
          font-size: 10px;
          color: white; }
        .stream nav .playlist li.active {
          position: relative;
          background-color: rgba(3, 169, 81, 0.3); }
  .stream .now-playing-active {
    background-position: left !important; }
  .stream .now-playing {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: darkgreen;
    transition: all 0.3s; }
    .stream .now-playing .overlay, .stream .now-playing .info, .stream .now-playing .controls, .stream .now-playing .vid {
      position: absolute;
      transition: all 0.3s; }
    .stream .now-playing .overlay {
      z-index: 4;
      left: 0;
      width: 0;
      height: 0;
      background-color: #000000;
      left: 0; }
    .stream .now-playing .top {
      top: 0; }
    .stream .now-playing .bottom {
      bottom: 0; }
    .stream .now-playing .info {
      z-index: 5;
      bottom: -68px;
      left: 0;
      width: 75%;
      padding: 10px 20px;
      box-sizing: border-box;
      opacity: 0.7;
      text-align: left;
      color: lime;
      pointer-events: none; }
      .stream .now-playing .info .small {
        font-size: 10px;
        padding-bottom: 10px;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        transition: all 0.3s; }
      .stream .now-playing .info h2 {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        color: lime; }
      .stream .now-playing .info h3 {
        font-size: 16px;
        text-transform: uppercase;
        color: lime; }
        .stream .now-playing .info h3 span {
          font-size: 12px;
          color: lime; }
  @media only screen and (max-width: 1350px) {
    .stream {
      position: relative;
      width: 100%;
      height: calc(100vh - 10rem) !important;
      overflow: hidden;
      border: 20px solid #313131; }
      .stream nav .playlist {
        width: 50% !important;
        height: calc(100vh - 10rem); } }
  @media only screen and (max-width: 768px) {
    .stream {
      position: relative;
      margin: auto;
      border: 0;
      margin: 4rem auto 0; }
      .stream .info {
        bottom: 10px !important; }
      .stream nav .menu-button {
        opacity: .4; } }
  @media only screen and (max-width: 600px) {
    .stream {
      position: relative; } }
