@import url(//fonts.googleapis.com/css?family=VT323);
#section-countdown {
  height: calc(100vh - 4rem);
  background-color: #313131;
  padding: 4rem 0; }
  #section-countdown #countdown {
    color: lime;
    width: 100%;
    margin-top: 50px; }
  #section-countdown #countError {
    color: lime;
    width: 100%;
    margin-top: 50px; }
  #section-countdown .count-text {
    text-align: center;
    color: lime; }
    #section-countdown .count-text a {
      font-family: 'VT323';
      color: #0f0; }
      #section-countdown .count-text a:hover {
        color: #00c800; }

@media only screen and (max-width: 2200px) {
  #countdown {
    width: 100%;
    margin-top: 50px; } }

@media only screen and (max-width: 1950px) {
  #countdown {
    width: 100%;
    margin-top: 50px; } }

@media only screen and (max-width: 1650px) {
  #countdown {
    width: 100%;
    margin-top: 50px; } }

@media only screen and (max-width: 1350px) {
  #countdown {
    width: 100%;
    margin-top: 50px; } }

@media only screen and (max-width: 1150px) {
  #countdown {
    width: 100%;
    margin-top: 50px; } }

@media only screen and (max-width: 736px) {
  #section-countdown {
    padding: 0; }
    #section-countdown #countdown {
      width: 100%; } }

@media only screen and (max-width: 575px) {
  #section-countdown {
    padding: 0; }
    #section-countdown #countdown {
      width: 100%;
      height: 100%;
      overflow-wrap: break-word; } }
